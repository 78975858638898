import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './footeri.css'

const Footeri = (props) => {
  return (
    <footer
      id={props.footer81Id}
      className={`footeri-container1 ${props.rootClassName} `}
    >
      <div className="footeri-max-width">
        <div id="footer81Id" className="footeri-content">
          <div className="footeri-newsletter">
            <img
              id="Navbar"
              alt={props.logoAlt}
              src={props.logoSrc}
              className="footeri-image1"
            />
            <div className="footeri-container2">
              <a
                href="https://apps.apple.com/us/app/icharge-app/id6479523452?l="
                target="_blank"
                rel="noreferrer noopener"
                className="footeri-link10"
              >
                <img
                  alt={props.logo2Alt}
                  src={props.logo2Src}
                  className="footeri-logo2"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.icharge.mobile.app"
                target="_blank"
                rel="noreferrer noopener"
                className="footeri-link11"
              >
                <img
                  alt={props.logo1Alt}
                  src={props.logo1Src}
                  className="footeri-logo1"
                />
              </a>
            </div>
          </div>
          <div className="footeri-links">
            <div className="footeri-column1">
              <strong>
                {props.column1Title ?? (
                  <Fragment>
                    <strong className="footeri-text19">Rreth nesh</strong>
                  </Fragment>
                )}
              </strong>
              <a href="#resources" className="footeri-link5">
                {props.link5 ?? (
                  <Fragment>
                    <span className="footeri-text23">Home</span>
                  </Fragment>
                )}
              </a>
              <a href="#ourstory" className="footeri-link3">
                {props.link3 ?? (
                  <Fragment>
                    <span className="footeri-text22">Rreth Nesh</span>
                  </Fragment>
                )}
              </a>
              <a href="#process" className="footeri-link4">
                {props.link4 ?? (
                  <Fragment>
                    <span className="footeri-text20">Perse Ne</span>
                  </Fragment>
                )}
              </a>
              <span>
                {props.link7 ?? (
                  <Fragment>
                    <span className="footeri-text26">Kontakt</span>
                  </Fragment>
                )}
              </span>
              <span>
                {props.link1 ?? (
                  <Fragment>
                    <span className="footeri-text21">Kushtet e Përdorimit</span>
                  </Fragment>
                )}
              </span>
              <div className="footeri-footer-links">
                <span>
                  {props.link2 ?? (
                    <Fragment>
                      <span className="footeri-text25">
                        Politika e Privatësisë
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
            <div className="footeri-container3">
              <div className="footeri-container4">
                <span className="footeri-content2">
                  <span className="footeri-text10">Kontakt</span>
                  <br className="footeri-text11"></br>
                  <br className="footeri-text12"></br>
                  <a href="tel:+355694080227" className="footeri-link12">
                    Tel:+
                  </a>
                  <a href="tel:+355694080227" className="footeri-link13">
                    355694080227
                  </a>
                  <a href="tel:+355694080227">
                    <br className="footeri-text13"></br>
                  </a>
                  <a
                    href="mailto:office@icharge.app?subject="
                    className="footeri-link15"
                  >
                    Email:office@icharge.app
                  </a>
                  <br></br>
                </span>
              </div>
              <div className="footeri-social-links">
                <a
                  href="https://www.facebook.com/icharge.alb"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footeri-link16"
                >
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="footeri-icon1"
                  >
                    <path d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"></path>
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/icharge.alb/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footeri-link17"
                >
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="footeri-icon3"
                  >
                    <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/in/icharge-app-b4a95832b/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footeri-link18"
                >
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="footeri-icon5"
                  >
                    <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                  </svg>
                </a>
                <a
                  href="https://www.tiktok.com/@icharge.app"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footeri-link19"
                >
                  <svg viewBox="0 0 24 24" className="footeri-icon7">
                    <path
                      d="M8.3 1.01c.75-.01 1.5 0 2.25-.01c.05.89.36 1.8 1.01 2.43c.64.65 1.55.94 2.44 1.04v2.35c-.83-.03-1.66-.2-2.42-.56c-.33-.15-.63-.34-.93-.54c0 1.7 0 3.41-.01 5.1c-.04.82-.31 1.63-.78 2.3c-.75 1.12-2.06 1.85-3.4 1.87c-.82.05-1.65-.18-2.35-.6c-1.16-.69-1.98-1.97-2.1-3.33q-.03-.435 0-.87c.1-1.11.65-2.17 1.49-2.89c.95-.84 2.29-1.24 3.54-1c.01.86-.02 1.73-.02 2.59c-.57-.19-1.24-.13-1.74.22c-.37.24-.64.6-.79 1.02c-.12.3-.09.62-.08.94c.14.96 1.05 1.76 2.01 1.67c.64 0 1.26-.39 1.59-.94c.11-.19.23-.39.24-.62c.06-1.04.03-2.08.04-3.13c0-2.35 0-4.7.01-7.04"
                      fill="currentColor"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <span className="footeri-content1">
            <span>Adresa: Rruga e Kavajes,</span>
            <br></br>
            <span>km i 1, Godina ne krahe te Vetingut.</span>
            <br></br>
          </span>
        </div>
        <div className="footeri-credits">
          <div className="footeri-row">
            <span>
              {props.copyright ?? (
                <Fragment>
                  <span className="footeri-text24">
                    © 2024 iCharge.app. Të gjitha të drejtat e rezervuara.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footeri.defaultProps = {
  logo1Src: '113b3792-aeac-44f2-b5d5-59ac7c7435a2',
  rootClassName: '',
  column1Title: undefined,
  logo2Alt: 'Logoja 2 e iCharge.app',
  link4: undefined,
  logo2Src: 'adb061cd-79ef-4206-9ef6-58fcab0ad777',
  logoSrc: '3a043fb0-5b0a-4e56-bc59-9c0eae6e76c0',
  logoAlt: 'iCharge Logo',
  link1: undefined,
  link3: undefined,
  logo1Alt: 'Logoja 1 e iCharge.app',
  link5: undefined,
  copyright: undefined,
  footer81Id: '',
  link2: undefined,
  link7: undefined,
}

Footeri.propTypes = {
  logo1Src: PropTypes.string,
  rootClassName: PropTypes.string,
  column1Title: PropTypes.element,
  logo2Alt: PropTypes.string,
  link4: PropTypes.element,
  logo2Src: PropTypes.string,
  logoSrc: PropTypes.string,
  logoAlt: PropTypes.string,
  link1: PropTypes.element,
  link3: PropTypes.element,
  logo1Alt: PropTypes.string,
  link5: PropTypes.element,
  copyright: PropTypes.element,
  footer81Id: PropTypes.string,
  link2: PropTypes.element,
  link7: PropTypes.element,
}

export default Footeri
