import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pyetje-te-shpeshta.css'

const PyetjeTeShpeshta = (props) => {
  const [faq3Visible, setFaq3Visible] = useState(false)
  const [faq1Visible, setFaq1Visible] = useState(false)
  const [faq2Visible, setFaq2Visible] = useState(false)
  const [faq4Visible, setFaq4Visible] = useState(false)
  return (
    <div
      className={`pyetje-te-shpeshta-faq8 thq-section-padding ${props.rootClassName} `}
    >
      <div className="pyetje-te-shpeshta-max-width thq-section-max-width thq-flex-row">
        <div className="pyetje-te-shpeshta-section-title thq-flex-column">
          <div className="pyetje-te-shpeshta-content thq-flex-column">
            <h2>
              {props.heading1 ?? (
                <Fragment>
                  <h2 className="pyetje-te-shpeshta-text16 thq-heading-2">
                    Pyetje të shpeshta
                  </h2>
                </Fragment>
              )}
            </h2>
            <p>
              {props.content1 ?? (
                <Fragment>
                  <p className="pyetje-te-shpeshta-text22 thq-body-large">
                    <span> </span>
                    <span className="pyetje-te-shpeshta-text24">
                      Nëse keni pyetje:
                    </span>
                  </p>
                </Fragment>
              )}
            </p>
          </div>
          <button className="pyetje-te-shpeshta-button thq-button-filled thq-button-animated">
            <span>
              {props.action ?? (
                <Fragment>
                  <span className="pyetje-te-shpeshta-text18 thq-body-small">
                    Na kontaktoni
                  </span>
                </Fragment>
              )}
            </span>
          </button>
        </div>
        <div className="pyetje-te-shpeshta-list thq-flex-column">
          <div className="pyetje-te-shpeshta-faq1">
            <div
              onClick={() => setFaq1Visible(!faq1Visible)}
              className="pyetje-te-shpeshta-trigger1"
            >
              <p>
                {props.faq1Question ?? (
                  <Fragment>
                    <p className="pyetje-te-shpeshta-text21 thq-body-large">
                      Cilet karikues janë kompatibel me iCharge?
                    </p>
                  </Fragment>
                )}
              </p>
              <div className="pyetje-te-shpeshta-icons-container1">
                {!faq1Visible && (
                  <div>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pyetje-te-shpeshta-icon10"
                    >
                      <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                    </svg>
                  </div>
                )}
                {faq1Visible && (
                  <div>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pyetje-te-shpeshta-icon12"
                    >
                      <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
            {faq1Visible && (
              <div className="pyetje-te-shpeshta-container12">
                <span className="pyetje-te-shpeshta-text12 thq-body-small">
                  iCharge mund të mbështesë çdo karikues në përputhje me OCPP.
                  Ky është një protokoll komunikimi që lejon karikuesit të
                  lidhen me sisteme të palëve të treta si iCharge. Ju mund të
                  kontrolloni dokumentacionin teknik te karikuesit tuaj per
                  kompatibilitetin me OCPP.
                </span>
              </div>
            )}
          </div>
          <div className="pyetje-te-shpeshta-faq2">
            <div
              onClick={() => setFaq2Visible(!faq2Visible)}
              className="pyetje-te-shpeshta-trigger2"
            >
              <p>
                {props.faq2Question ?? (
                  <Fragment>
                    <p className="pyetje-te-shpeshta-text20 thq-body-large">
                      A duhet të nënshkruaj një kontratë me iCharge?
                    </p>
                  </Fragment>
                )}
              </p>
              <div className="pyetje-te-shpeshta-icons-container2">
                {!faq2Visible && (
                  <div>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pyetje-te-shpeshta-icon14"
                    >
                      <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                    </svg>
                  </div>
                )}
                {faq2Visible && (
                  <div>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pyetje-te-shpeshta-icon16"
                    >
                      <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
            {faq2Visible && (
              <div className="pyetje-te-shpeshta-container15">
                <span className="pyetje-te-shpeshta-text13 thq-body-small">
                  Jo, ju jeni të lirë të lidhni dhe te shkepusni karikuesit tuaj
                  nga platforma sa here te deshironi. Kushtet &amp; Rregullat
                  tona zbatohen kur përdorni aplikacionin, të cilat ofrojnë të
                  gjitha mbrojtjet e nevojshme per te dhenat tuaja.
                </span>
              </div>
            )}
          </div>
          <div className="pyetje-te-shpeshta-faq3">
            <div
              onClick={() => setFaq3Visible(!faq3Visible)}
              className="pyetje-te-shpeshta-trigger3"
            >
              <p>
                {props.faq3Question ?? (
                  <Fragment>
                    <p className="pyetje-te-shpeshta-text17 thq-body-large">
                      Sa kohë merr procesi i karikimit të makinës elektrike?
                    </p>
                  </Fragment>
                )}
              </p>
              <div className="pyetje-te-shpeshta-icons-container3">
                {!faq3Visible && (
                  <div>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pyetje-te-shpeshta-icon18"
                    >
                      <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                    </svg>
                  </div>
                )}
                {faq3Visible && (
                  <div>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pyetje-te-shpeshta-icon20"
                    >
                      <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
            {faq3Visible && (
              <div className="pyetje-te-shpeshta-container18">
                <span className="pyetje-te-shpeshta-text14 thq-body-small">
                  Kërkesa kryesore për mundësinë e lidhjes së një karikuesi
                  është lidhja e internetit. Zakonisht, koha e nevojshme për të
                  konfiguruar një karikues zgjat më pak se një orë. Megjithatë,
                  kjo mund të variojë në varësi të rrethanave dhe mundësive për
                  lidhjen fizike të internetit.
                </span>
              </div>
            )}
          </div>
          <div className="pyetje-te-shpeshta-faq4">
            <div
              onClick={() => setFaq4Visible(!faq4Visible)}
              className="pyetje-te-shpeshta-trigger4"
            >
              <p>
                {props.faq4Question ?? (
                  <Fragment>
                    <p className="pyetje-te-shpeshta-text19 thq-body-large">
                      Si mund te perdor applikacionin Mobile?
                    </p>
                  </Fragment>
                )}
              </p>
              <div className="pyetje-te-shpeshta-icons-container4">
                {!faq4Visible && (
                  <div>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pyetje-te-shpeshta-icon22"
                    >
                      <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                    </svg>
                  </div>
                )}
                {faq4Visible && (
                  <div>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pyetje-te-shpeshta-icon24"
                    >
                      <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
            {faq4Visible && (
              <div className="pyetje-te-shpeshta-container21">
                <span className="pyetje-te-shpeshta-text15 thq-body-small">
                  Ju mund të krijoni llogaritë e klientëve tuaj në platformë dhe
                  t&apos;u mundësoni atyre identifikimin nëpërmjet aplikacionit
                  mobile. Përdoruesit kanë mundësinë të përdorin aplikacionin
                  mobile për të marrë informacione rreth vendndodhjeve të
                  karikuesve, të nisin procesin e karikimit, si dhe të ruajnë
                  dhe konsultojnë historikun e tyre, etj.
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

PyetjeTeShpeshta.defaultProps = {
  rootClassName: '',
  heading1: undefined,
  faq3Question: undefined,
  action: undefined,
  faq4Question: undefined,
  faq2Question: undefined,
  faq1Question: undefined,
  content1: undefined,
}

PyetjeTeShpeshta.propTypes = {
  rootClassName: PropTypes.string,
  heading1: PropTypes.element,
  faq3Question: PropTypes.element,
  action: PropTypes.element,
  faq4Question: PropTypes.element,
  faq2Question: PropTypes.element,
  faq1Question: PropTypes.element,
  content1: PropTypes.element,
}

export default PyetjeTeShpeshta
