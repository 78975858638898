import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import PyetjeTeShpeshta from '../components/pyetje-te-shpeshta'
import Footeri from '../components/footeri'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>
          iCharge.app – Karikim i Shpejtë për Makinat Elektrike në Shqipëri
        </title>
        <meta
          name="description"
          content="iCharge.app ofron karikim të shpejtë për makinat elektrike. Menaxho stacionet e karikimit me lehtësi dhe udhëto me besim kudo në Shqipëri."
        />
        <meta
          property="og:title"
          content="iCharge.app – Karikim i Shpejtë për Makinat Elektrike në Shqipëri"
        />
        <meta
          property="og:description"
          content="iCharge.app ofron karikim të shpejtë për makinat elektrike. Menaxho stacionet e karikimit me lehtësi dhe udhëto me besim kudo në Shqipëri."
        />
      </Helmet>
      <header className="home-container2">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <img
            alt="iCharge Logo"
            src="/logo_icharge_primary_1%20(1)-1500h.png"
            className="home-image1"
          />
          <div data-thq="thq-navbar-nav" className="home-desktop-menu">
            <nav className="home-links1">
              <a
                href="#resources"
                className="home-link11 thq-link thq-body-small"
              >
                Home
              </a>
              <a
                href="#inspiration"
                className="home-link21 thq-link thq-body-small"
              >
                Rreth nesh
              </a>
              <a
                href="#process"
                className="home-link31 thq-link thq-body-small"
              >
                Perse Ne?
              </a>
              <span className="home-link41 thq-link thq-body-small">
                Kontakt
              </span>
            </nav>
            <div className="home-buttons">
              <button className="home-action1 thq-button-filled thq-button-animated">
                <span className="home-text10 thq-body-small">DOWNLOAD</span>
              </button>
            </div>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <svg viewBox="0 0 1024 1024" className="home-icon10">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div className="home-nav">
              <div className="home-top">
                <img
                  alt="iCharge Logo"
                  src="/logo_icharge_primary_1%20(1)-1500h.png"
                  className="home-logo"
                />
                <div data-thq="thq-close-menu" className="home-close-menu">
                  <svg viewBox="0 0 1024 1024" className="home-icon12">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="home-links2">
                <a
                  href="#resources"
                  className="home-link12 thq-link thq-body-small"
                >
                  Home
                </a>
                <a
                  href="#ourstory"
                  className="home-link22 thq-link thq-body-small"
                >
                  Rreth nesh
                </a>
                <a
                  href="#process"
                  className="home-link32 thq-link thq-body-small"
                >
                  Perse Ne?
                </a>
                <a
                  href="#footer81Id"
                  className="home-link42 thq-link thq-body-small"
                >
                  Kontakt
                </a>
              </nav>
              {/* <a
                href="https://play.google.com/store/apps/details?id=com.icharge.mobile.app"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link5"
              >
                <img
                  alt="image"
                  src="/external/images-200h-200h.png"
                  className="home-image2"
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/icharge-app/id6479523452?l="
                target="_blank"
                rel="noreferrer noopener"
                className="home-link6"
              >
                <img
                  alt="image"
                  src="/external/png-transparent-get-it-on-google-play-button-200h-200h.png"
                  className="home-image3"
                />
              </a> */}
            </div>
          </div>
        </header>
      </header>
      <div id="resources" className="home-hero">
        <div className="home-content-container1">
          <div className="home-hero-text">
            <h1 className="home-heading1">
              <span className="home-text11">
                Karikim Efikas
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="home-text12"></br>
              <span className="home-text13">
                për një të Ardhme
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="home-text14"></br>
              <span className="home-text15">më të</span>
              <span className="home-text16"> </span>
              <span className="home-text17">Gjelbër.</span>
            </h1>
            <a
              href="https://m.icharge.app/"
              target="_blank"
              rel="noreferrer noopener"
              className="home-cta-btn thq-button-filled thq-button-animated"
            >
              START NOW
            </a>
          </div>
        </div>
      </div>
      <div id="ourstory" className="home-our-story">
        <div className="home-heading-container1">
          <h1 className="home-text18 Section-Heading">Kush jemi</h1>
          <span className="home-text19">
            {' '}
            iCharge.app eshte nje platforme inovative qe thjeshton karikimin e
            automjeteve elektrike (EV)
          </span>
        </div>
        <div className="home-cards-container1">
          <div className="home-left-section">
            <div className="home-video-container">
              <video
                poster="/untitled%20design-1500w.png"
                className="home-video"
              ></video>
            </div>
            <div className="home-content-container2">
              <span className="home-heading2">Misioni &amp; Vizioni</span>
              <span>
                <span className="Card-Text">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  iCharge.app ofron një zgjidhje fleksibël, të lehtë për
                  përdorim dhe ekonomike për menaxhimin e stacioneve të
                  karikimit të automjeteve elektrike
                </span>
                <span className="home-text23">. Ne jemi p</span>
                <span className="home-text24">
                  lotësisht të përkushtuar ndaj lehtësisë së përdorimit,
                  transparencës dhe besueshmërisë.
                </span>
              </span>
              <span>
                iCharge.app vendos një standard të ri për drejtuesit, bizneset
                dhe partnerët e industrisë, duke udhëhequr inovacionin në
                ekosistemin e automjeteve elektrike.
              </span>
            </div>
          </div>
          <div className="home-right-section">
            <div className="home-card1">
              <img
                alt="image"
                src="/moduli-4-1-icharge-krijo-llogarine-1500w.png"
                className="home-image4"
              />
              <div className="home-content-container3">
                <span>
                  <span>
                    Në aplikacionin iCharge.app, përdoruesit mund të gjejnë
                    stacionet më të afërta për karikimin e automjeteve
                    elektrike,
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br></br>
                  <br></br>
                </span>
              </div>
            </div>
            <div className="home-card2">
              <img
                alt="image"
                src="/moduli-4-2-icharge-skano-dhe-kariko-1500w.png"
                className="home-image5"
              />
              <div className="home-content-container4">
                <span>
                  {' '}
                  Duke përdorur kartën, mund të bëni rimbushje për karikimin e
                  automjetit tuaj elektrik. Ne ofrojm një përvojë të thjeshtë
                  dhe efikase për karikuar makinën tuaj.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="inspiration" className="home-features">
        <div className="home-heading-container2">
          <h2 className="home-text31">Rreth nesh</h2>
        </div>
        <div className="home-cards-container2">
          <div className="home-features-card1">
            <div className="home-icon-container1">
              <svg viewBox="0 0 1024 1024" className="home-icon14">
                <path d="M533.333 234.667c-11.776 0-21.333 9.557-21.333 21.333s9.557 21.333 21.333 21.333c46.208 0 83.797 37.589 83.797 83.797 0 11.776 9.557 21.333 21.333 21.333s21.333-9.557 21.333-21.333c0-69.717-56.747-126.464-126.464-126.464z"></path>
                <path d="M533.333 42.667c-176.469 0-320 143.531-320 320 0 63.403 18.773 124.672 54.357 177.707 0.981 2.005 2.091 3.925 3.413 5.76 77.867 111.189 91.563 146.347 91.563 179.2v128c0 23.552 19.115 42.667 42.667 42.667h85.333c0 11.093 4.693 22.187 12.373 30.293 8.107 7.68 19.2 12.373 30.293 12.373 11.093 0 22.187-4.693 30.293-12.373 7.68-8.107 12.373-19.2 12.373-30.293h85.333c23.552 0 42.667-19.115 42.667-42.667v-128c0-33.365 13.611-68.693 90.965-179.157 38.187-54.4 58.368-117.845 58.368-183.509 0-176.469-143.531-320-320-320zM618.667 810.667h-170.667v-42.667h170.667v42.667zM725.12 497.195c-62.549 89.301-91.435 140.331-101.76 185.472h-47.36v-85.333c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v85.333h-47.488c-10.24-43.947-38.315-93.867-98.389-180.053-0.939-1.877-2.048-3.712-3.285-5.504-28.032-39.851-42.837-86.357-42.837-134.443 0-129.408 105.259-234.667 234.667-234.667s234.667 105.259 234.667 234.667c0 48.043-14.805 94.549-42.88 134.528z"></path>
              </svg>
            </div>
            <div className="home-text-container1">
              <span className="home-heading3">Pika Karikimi</span>
              <span className="home-text32">50</span>
            </div>
          </div>
          <div className="home-features-card2">
            <div className="home-icon-container2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="home-icon17"
              >
                <path
                  d="m11.775 19.075l4.15-8.1q.125-.25-.025-.488t-.425-.237H12.8V5.025q0-.2-.187-.238t-.288.138l-4.15 8.1q-.125.25.025.488t.425.237H11.3v5.225q0 .2.188.238t.287-.138M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.325 0 5.663-2.337T20 12t-2.337-5.663T12 4T6.337 6.338T4 12t2.338 5.663T12 20"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
            <div className="home-text-container2">
              <span className="home-heading4">Tonelata CO2 të kursyera</span>
              <span className="home-text33">100</span>
            </div>
          </div>
          <div className="home-features-card3">
            <div className="home-icon-container3">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="home-icon19"
              >
                <path
                  d="M6 10.5h6V5.616q0-.231-.192-.424T11.385 5h-4.77q-.23 0-.423.192T6 5.616zM5 20V5.616q0-.691.463-1.153T6.616 4h4.769q.69 0 1.153.463T13 5.616v6h.98q.649 0 1.104.454q.455.455.455 1.103v4.606q0 .54.436.938q.437.399.996.399q.585 0 1.009-.399q.424-.398.424-.938V8.885h-.346q-.348 0-.578-.23t-.23-.578V6.5h.404V5.385h.769V6.5h.846V5.385h.77V6.5h.403v1.577q0 .348-.23.578t-.578.23h-.346v8.894q0 .915-.676 1.568q-.675.653-1.64.653q-.941 0-1.63-.653q-.688-.653-.688-1.568v-4.606q0-.298-.188-.486t-.485-.187H13V20zm3.654-1.884l1.769-3.231H9.366v-2.52l-1.789 3.231h1.077z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
            <div className="home-text-container3">
              <span className="home-heading5">Chargers</span>
              <span className="home-text34">90</span>
            </div>
          </div>
          <div className="home-features-card4">
            <div className="home-icon-container4">
              <svg width="36" height="36" viewBox="0 0 36 36">
                <path
                  d="M26.54 18a19.4 19.4 0 0 0-.43-4h3.6a12 12 0 0 0-.67-1.6h-3.35a19.7 19.7 0 0 0-2.89-5.87a12.3 12.3 0 0 0-2.55-.76a17.8 17.8 0 0 1 3.89 6.59h-5.39V5.6h-1.5v6.77h-5.39a17.8 17.8 0 0 1 3.9-6.6a12.3 12.3 0 0 0-2.54.75a19.7 19.7 0 0 0-2.91 5.85H6.94A12 12 0 0 0 6.26 14h3.63a19.4 19.4 0 0 0-.43 4a19.7 19.7 0 0 0 .5 4.37H6.42A12 12 0 0 0 7.16 24h3.23a19.3 19.3 0 0 0 2.69 5.36a12.3 12.3 0 0 0 2.61.79A17.9 17.9 0 0 1 12 24h5.26v6.34h1.5V24H24a17.9 17.9 0 0 1-3.7 6.15a12.3 12.3 0 0 0 2.62-.81A19.3 19.3 0 0 0 25.61 24h3.2a12 12 0 0 0 .74-1.6H26a19.7 19.7 0 0 0 .54-4.4m-9.29 4.37h-5.74a17.7 17.7 0 0 1-.09-8.4h5.83Zm7.24 0h-5.74V14h5.83a18.2 18.2 0 0 1 .42 4a18 18 0 0 1-.51 4.37"
                  fill="currentColor"
                  className="clr-i-outline clr-i-outline-path-1"
                ></path>
                <path
                  d="M18 2a16 16 0 1 0 16 16A16 16 0 0 0 18 2m0 30a14 14 0 1 1 14-14a14 14 0 0 1-14 14"
                  fill="currentColor"
                  className="clr-i-outline clr-i-outline-path-2"
                ></path>
                <path d="M0 0h36v36H0z" fill="none"></path>
              </svg>
            </div>
            <div className="home-text-container4">
              <span className="home-heading6">Qytete </span>
              <span className="home-text35">10</span>
            </div>
          </div>
          <div className="home-features-card5">
            <div className="home-icon-container5">
              <svg width="36" height="36" view-box="0 0 36 36">
                <path
                  d="M18 2a16 16 0 1 0 16 16A16 16 0 0 0 18 2m0 30a14 14 0 1 1 14-14a14 14 0 0 1-14 14"
                  fill="currentColor"
                  className="clr-i-outline clr-i-outline-path-1"
                ></path>
                <path
                  d="M28 12.1a1 1 0 0 0-1.41 0l-11.1 11.05l-6-6A1 1 0 0 0 8 18.53L15.49 26L28 13.52a1 1 0 0 0 0-1.42"
                  fill="currentColor"
                  className="clr-i-outline clr-i-outline-path-2"
                ></path>
                <path d="M0 0h36v36H0z" fill="none"></path>
              </svg>
            </div>
            <div className="home-text-container5">
              <span className="home-heading7">Sukses i Karikimit</span>
              <span className="home-text36">98%</span>
            </div>
          </div>
          <div className="home-features-card6">
            <div className="home-icon-container6">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="home-icon29"
              >
                <g fill="none" stroke="currentColor" stroke-width="2">
                  <circle r="3" cx="7" cy="6"></circle>
                  <path
                    d="M10 13H5.818a3 3 0 0 0-2.964 2.537L2.36 18.69A2 2 0 0 0 4.337 21H9m12.64-2.309l-.494-3.154A3 3 0 0 0 18.182 13h-2.364a3 3 0 0 0-2.964 2.537l-.493 3.154A2 2 0 0 0 14.337 21h5.326a2 2 0 0 0 1.976-2.309"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <circle r="3" cx="17" cy="6"></circle>
                </g>
              </svg>
            </div>
            <div className="home-text-container6">
              <span className="home-heading8">Partner </span>
              <span className="home-text37">15</span>
            </div>
          </div>
        </div>
      </div>
      <div id="process" className="home-services">
        <div className="home-heading-container3">
          <h1 className="home-text38">
            Pse duhet te zgjidhni platformen tone?
          </h1>
        </div>
        <div className="home-cards-container3">
          <div className="home-service-card1">
            <h3 className="home-text39">Pershtatshmeri Universale</h3>
            <span className="home-text40">
              <span className="Card-Text"> </span>
              <span>
                Zgjidhja jone lejon integrimin pa probleme te cdo stacioni te
                karikimit
              </span>
            </span>
          </div>
          <div className="home-service-card2">
            <h3 className="home-text43">Kosto-efektive</h3>
            <span className="home-text44">
              <span className="Card-Text"> </span>
              <span>
                Sistemi yne eshte projektuar per te qene ekonomik, duke ju
                ofruar cilesi te larte me koston me te ulet
              </span>
              <span className="Card-Text">
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </div>
          <div className="home-service-card3">
            <h3 className="home-text48">Lehtësi në Përdorim</h3>
            <span className="home-text49">
              <span className="Card-Text"> </span>
              <span>
                Platforma ofron një ndërfaqe intuitive që lehtëson menaxhimin e
                stacioneve të karikimit.
              </span>
              <span className="Card-Text">
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="home-team">
        <div className="home-heading-container4">
          <h1 className="home-heading9">
            Si te karikosh me 4 hapa te thjeshte ?
          </h1>
        </div>
        <div className="home-cards-container4">
          <div className="home-team-card1">
            <div className="home-avatar-container1">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="home-icon34"
              >
                <path
                  d="M14 1.5V22h-2V3.704L7.5 4.91V2.839l5-1.339z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
            <span className="home-position1">Shkarko applikacionin</span>
          </div>
          <div className="home-team-card2">
            <div className="home-avatar-container2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="home-icon36"
              >
                <path
                  d="M16 7.5a4 4 0 1 0-8 0H6a6 6 0 1 1 10.663 3.776l-7.319 8.723L18 20v2H6v-1.127l9.064-10.802A3.98 3.98 0 0 0 16 7.5"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
            <span className="home-position2">Krijo llogarine</span>
          </div>
          <div className="home-team-card3">
            <div className="home-avatar-container3">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="home-icon38"
              >
                <path
                  d="M18 2v1.362L12.81 9.55a6.501 6.501 0 1 1-7.116 8.028l1.94-.486A4.502 4.502 0 0 0 16.5 16a4.5 4.5 0 0 0-6.505-4.03l-.228.122l-.69-1.207L14.855 4H6.5V2z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
            <span className="home-position3">Gjej piken e karikimit</span>
          </div>
          <div className="home-team-card4">
            <div className="home-avatar-container4">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="home-icon40"
              >
                <path
                  d="M16 1.5V16h3v2h-3v4h-2v-4H4v-1.102L14 1.5zM14 16V5.171L6.968 16z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
            <span className="home-position4">Skano dhe kariko</span>
          </div>
          <div className="home-team-card5">
            <div className="home-avatar-container5">
              <svg viewBox="0 0 1024 1024" className="home-icon42">
                <path d="M576 706.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"></path>
              </svg>
            </div>
            <span className="Card-Heading">Ben</span>
            <span className="Card-Text">Position</span>
          </div>
        </div>
        <PyetjeTeShpeshta
          action={
            <Fragment>
              <span className="home-text53 thq-body-small">Na kontaktoni</span>
            </Fragment>
          }
          content1={
            <Fragment>
              <span className="home-text54 thq-body-large">
                <span> </span>
                <span className="home-text56">Nëse keni pyetje:</span>
              </span>
            </Fragment>
          }
          heading1={
            <Fragment>
              <span className="home-text57 thq-heading-2">
                Pyetje të shpeshta
              </span>
            </Fragment>
          }
          faq1Question={
            <Fragment>
              <span className="home-text58 thq-body-large">
                Cilet karikues janë kompatibel me iCharge?
              </span>
            </Fragment>
          }
          faq2Question={
            <Fragment>
              <span className="home-text59 thq-body-large">
                A duhet të nënshkruaj një kontratë me iCharge?
              </span>
            </Fragment>
          }
          faq3Question={
            <Fragment>
              <span className="home-text60 thq-body-large">
                Sa kohë merr procesi i karikimit të makinës elektrike?
              </span>
            </Fragment>
          }
          faq4Question={
            <Fragment>
              <span className="home-text61 thq-body-large">
                Si mund te perdor applikacionin Mobile?
              </span>
            </Fragment>
          }
          rootClassName="pyetje-te-shpeshtaroot-class-name"
        ></PyetjeTeShpeshta>
      </div>
      <div className="home-banner">
        <h1 className="home-text62">
          <span>Financim per pika karikimi</span>
          <br></br>
          <span>makinash elektrike.</span>
          <br></br>
        </h1>
        <span className="home-text67">
          <span>Na kontaktoni per te eksploruar mundesite e financimit</span>
          <br></br>
          <span>dhe per te realizuar vizionin tuaj per nje te ardhme me </span>
          <br></br>
          <span>te gjelber.</span>
          <br></br>
        </span>
      </div>
      <div className="home-section-separator1"></div>
      <div className="home-section-separator2"></div>
      <div className="home-section-separator3"></div>
      <div className="home-footer-container">
        <Footeri
          link1={
            <Fragment>
              <span className="home-text74">Kushtet e Përdorimit</span>
            </Fragment>
          }
          link2={
            <Fragment>
              <span className="home-text75">Politika e Privatësisë</span>
            </Fragment>
          }
          link3={
            <Fragment>
              <span className="home-text76">Rreth nesh</span>
            </Fragment>
          }
          link4={
            <Fragment>
              <span className="home-text77">Perse Ne?</span>
            </Fragment>
          }
          link5={
            <Fragment>
              <span className="home-text78">Home</span>
            </Fragment>
          }
          link7={
            <Fragment>
              <span className="home-text79">Kontakt</span>
            </Fragment>
          }
          logoSrc="/external/logo_icharge_primary_page-0002-removebg-preview-200h-200h.png"
          logo1Src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/66e2351f-d865-42c9-ac5b-d35146f9af2e/bda8842d-c019-4cc5-aefc-d974582de83a?org_if_sml=12556&amp;force_format=original"
          logo2Src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/66e2351f-d865-42c9-ac5b-d35146f9af2e/a5d1ce3e-f30c-404e-a69e-76b634bcac96?org_if_sml=14971&amp;force_format=original"
          copyright={
            <Fragment>
              <span className="home-text80">
                © 2024 iCharge.app. Të gjitha të drejtat e rezervuara.
              </span>
            </Fragment>
          }
          footer81Id="Footer81"
          column1Title={
            <Fragment>
              <span className="home-text81">Rreth nesh</span>
            </Fragment>
          }
          rootClassName="footeriroot-class-name"
          className="home-component2"
        ></Footeri>
      </div>
    </div>
  )
}

export default Home
